import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import $, { trim } from 'jquery';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';
//import { Map, GoogleApiWrapper } from 'google-maps-react';
import GoogleMapReact from 'google-map-react';
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const mapStyles = {
  width: '100%',
  height: '100%'
};

function Pdp() {
  const { skuprod } = useParams();
  return <Pdpblock skuprod={skuprod} />
}
function Preloadcatalog() {
  const { page } = useParams();
  const { search } = useParams();
  return <LoadCatalog key={page} page={page} search={search} />
}
/*
class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={
          {
            lat: 45.470397,
            lng: 9.196931
          }
        }
      />
    );
  }
}

GoogleApiWrapper({
  apiKey: 'AIzaSyAsFcBO6nKi0DO8VOQQPtP5HzyRp4JxvOg'
})(MapContainer);
*/
class Mymap extends Component {
  static defaultProps = {
    center: {
      lat: 45.470397,
      lng: 9.196931
    },
    zoom: 16
  };

  render() {
    return (

      <div style={{ height: '50vh', width: '50%', float: 'left' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAsFcBO6nKi0DO8VOQQPtP5HzyRp4JxvOg" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={45.470397}
            lng={9.196931}
            text="Store demo"
          />
        </GoogleMapReact>
      </div>

    );
  }
}
class Popup extends Component {
  render() {
    return (
      <div className='popup'>
        <div className='popup_inner'>
          <Mymap />
          <div className="descriptionextothemap">Store:  Milano – Via della Spiga 2</div>
          <div className="thexclose" onClick={this.props.closePopup}>X</div>
        </div>
      </div>
    );
  }
}




class Mypopup extends Component {

  constructor(props) {
    super(props);
    this.state = { showPopup: false };
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  render() {
    return (
      <div className="avaibilitystores">


        <div onClick={this.togglePopup.bind(this)} className="avaibilitystorestitle">SHOP NOW</div>
        {this.state.showPopup ?
          <Popup text='' closePopup={this.togglePopup.bind(this)} />
          : null
        }
      </div>

    );
  }
}






class Pdpblock extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      item: [],
      skuprod: null,
      currentimg: 0,
      search: null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ search: event.target.value });
  }
  componentDidMount() {
    this._isMounted = true;
    var url = `http://camerabuyer.jef.it/productview/${this.props.skuprod}`;
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            item: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const { error, isLoaded, item, currentimg, search } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {
      const gallery = []
      var price = item.attribute.supply_price;
      price = parseFloat(price).toFixed(2);
      var longdescription = "";
      if (item.child[0].long_desc !== undefined) { longdescription = item.child[0].long_desc[0]; }
      for (const [index, value] of item.images.entries()) {
        gallery.push(<span className="boxgallery" >
          <img className="galleryimg" onClick={() => this.setState({ currentimg: index })} src={value} />
        </span>)
      }
      const size = []
      { item.child.map(child => size.push(<li >{child.item_size[0]} ({child.stock[0]})</li>)) }
      return (
        <div className="container">
          <div className="searchboxheader row">
            <div className="col-xs-10"><input id="searchkey" type="text" value={this.state.value} onChange={this.handleChange} className="searchbox" /></div>
            <div className="col-xs-2">
              <ActionLink page="1" search={search} button="yes" /></div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ol className="breadcrumb">
                <li key="home"><a href="/">Home</a></li>
                <li className="active" key="product">{item.description}</li>
              </ol>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-6">
              <div className="single-product-slider">
                <div id='carousel-custom' class='carousel slide' data-ride='carousel'>
                  <div class='caro'>
                    <div class='caro '>
                      <div class='item active'>
                        <InnerImageZoom src={item.images[this.state.currentimg]} zoomSrc={item.images[this.state.currentimg]} />
                      </div>
                    </div>
                  </div>
                  <ol className="carousel-indicators mCustomScrollbar meartlab">
                    {gallery}
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-product-details">
                <div class="pdpname">{item.product_name}</div>
                <div class="pdpbrand">{item.producer_id} </div>
                <div class="pdpdescription"> {item.description} </div>

                <div className="product-price"> {price} &euro;</div>
                <div className="product-size">
                  <span>Taglia:</span>
                  <ul className="pdpsize">
                    {size}
                  </ul>
                </div> <Mypopup />
                <div className="product-descriptiontitle mt-20">Descrizione dettagliata</div>

                <div className="product-description mt-20">
                  {longdescription}
                </div>

                <div className="product-description mt-20">

                  <div className="boxaddattr">

                    <div className="boxaddrattrtitle">Marchio</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.producer_id}</div>
                    <div className="boxaddrattrtitle">Tipologia</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.type}</div>
                    <div className="boxaddrattrtitle">Stagione</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.season}</div>
                    <div className="boxaddrattrtitle">Sku</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.product_name}</div>
                    <div className="boxaddrattrtitle">Descrizione</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.description}</div>
                    <div className="boxaddrattrtitle">Categoria</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.category}</div>
                    <div className="boxaddrattrtitle">Dettagli</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.product_detail}</div>
                    <div className="boxaddrattrtitle">Prodotto in</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.product_MadeIn}</div>
                    <div className="boxaddrattrtitle">Materiale</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.product_Material}</div>
                    <div className="boxaddrattrtitle">Dimensioni</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.product_Measure}</div>
                    <div className="boxaddrattrtitle">Prezzo</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.supply_price}</div>
                    <div className="boxaddrattrtitle">Riporto</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.CarryOver}</div>
                    <div className="boxaddrattrtitle">Note</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.product_Note}</div>
                    <div className="boxaddrattrtitle">Categoria</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.SubCategory}</div>
                    <div className="boxaddrattrtitle">Tacco</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.heel}</div>
                    <div className="boxaddrattrtitle">Suola</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.sole}</div>
                    <div className="boxaddrattrtitle">Asta</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.asta}</div>
                    <div className="boxaddrattrtitle">Ponte</div><div className="boxaddrattrcontent">&nbsp;{item.attribute.ponte}</div>
                    <div className="boxaddrattrtitle">Misura (asia) </div><div className="boxaddrattrcontent">&nbsp;{item.attribute.asian_fit}</div>

                  </div>




                </div>




              </div>
            </div>
          </div>
        </div>);
    }
  }
}
class Footer extends React.Component {
  render() {
    return (
      <>
        <footer className="footer section text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p className="copyright-text">Powered by <a target="_blank" href="https://www.jef.it/">Jef s.r.l.</a></p>
              </div>
            </div>
          </div>
        </footer>
      </>);
  }
}
class Header extends React.Component {
  render() {
    return (
      <>
        <div className="container top-header">
          <div className="row">
            <div className="col-md-4 col-xs-12 col-sm-4">
              <div className="contact">
                <ul>
                  <li><a href="https://www.instagram.com/camerabuyer/" target="_blank"><img src="https://www.camerabuyer.it/wp-content/uploads/2020/05/IG-50x50.png" /></a></li>
                  <li><a href="https://www.facebook.com/CameraBuyerItalia/" target="_blank"><img src="https://www.camerabuyer.it/wp-content/uploads/2020/05/FACEBOOK-50x50.png" /></a></li>
                  <li><a href="https://www.linkedin.com/company/10622354/" target="_blank"><img src="https://www.camerabuyer.it/wp-content/uploads/2020/05/LINKEDIN-50x50.png" /></a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-xs-12 col-sm-4">
              <div className="logo text-center">
                <a href="/">
                  <img src="https://www.camerabuyer.it/wp-content/uploads/2019/06/CBI-Camera-buyer-italia.png" />
                </a>
              </div>
            </div>
            <div className="col-md-4 col-xs-12 col-sm-4">
            </div>
          </div>
        </div>
      </>);
  }
}
export default function App() {
  return (
    <div><Header />
      <Router>
        <Switch>
          <Route path="/pdp/:skuprod">
            <Pdp />
          </Route>
          <Route path="/:page/:search" >
            <Preloadcatalog />
          </Route>
          <Route path="/:page" >
            <Preloadcatalog />
          </Route>
          <Route path="/">
            <Preloadcatalog page="1" search="" />
          </Route>
        </Switch>
      </Router><Footer /></div>
  );
}
class Getphoto extends Component {
  render() {
    return (
      <span className="boxgallery">
        <img className="galleryimg" src={this.props.src} />
      </span>
    );
  }
}
class LoadCatalog extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      page: null,
      search: null
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({ search: event.target.value });
  }
  componentDidMount() {
    var pagecurrent;
    if (this.props.page === undefined) { pagecurrent = 1; }
    else { pagecurrent = this.props.page; }
    this._isMounted = true;
    if ((this.props.search == null) || (this.props.search === undefined)) { var url = `http://camerabuyer.jef.it/catalog/${pagecurrent}/40`; }
    else { var url = `http://camerabuyer.jef.it/catalog/${pagecurrent}/40/${this.props.search}`; }
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const { error, isLoaded, items, search } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {
      var pagecurrent;
      if (this.props.page === undefined) { pagecurrent = 1; }
      else { pagecurrent = this.props.page; }
      return (<div className="container">
        <div className="row">
          <div className="searchboxheader row">
            <div className="col-xs-10"><input id="searchkey" type="text" value={this.state.value} onChange={this.handleChange} className="searchbox" /></div>
            <div className="col-xs-2">
              <ActionLink page="1" search={search} button="yes" /></div>
          </div>
          <Filter search={this.props.search} />

          {items.map(item => (
            <div className="col-md-3">
              <div className="product-item">
                <div className="product-thumb">
                  <Link to={`/pdp/${item.sku}`}><Getphoto src={item.images[0]}></Getphoto></Link>
                </div>
                <div className="product-content">
                  <h4>
                    <Link to={`/pdp/${item.sku}`}>

                      <div className="productsku">{item.product_name}</div>
                      <div className="productbrand">{item.producer_id} </div>
                      <div className="productname">{item.description}</div>
                      <div className="scopri">SCOPRI</div>
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </div><Pagination active={pagecurrent} search={this.props.search} /></div>
      );
    }
  }
}
function ActionLink(props) {
  function handleClick(e) {
    e.preventDefault();
    const element = <LoadCatalog page={props.page} search={props.search} />;
  }
  if (props.button == "yes") {
    if ((props.search != "") && (props.search !== undefined) && (props.search !== null)) {
      var url2 = `/${props.page}/${props.search}`;
      return (<a href={url2} >
        <div className="begintosearch iiz__btn iiz__hint" >
        </div>
      </a>);
    }
    else {
      return '';
    }
  }
  else {
    if ((props.search != "") && (props.search !== undefined)) {
      if (props.symbol === undefined) {
        return (<Link to={`/${props.page}/${props.search}`}>{props.page} </Link>);
      } else {
        return (<Link to={`/${props.page}/${props.search}`}>{props.symbol} </Link>)
      }
    }
    else {
      if (props.symbol === undefined) { return (<Link to={`/${props.page}`}>{props.page} </Link>); }
      else { return (<Link to={`/${props.page}`}>{props.symbol} </Link>); }
    }
  }
}
class Pagination extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      total: [],
      active: null,
      search: null
    };
  }
  componentDidMount() {
    this._isMounted = true;
    if ((this.props.search != null) && (this.props.search !== undefined)) {
      var url = `http://camerabuyer.jef.it/totproducts/${this.props.search}`;
    }
    else { var url = `http://camerabuyer.jef.it/totproducts/`; }
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            total: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const { error, isLoaded, total } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      var pages = Math.ceil(total[0].hm / 40);
      var i; var paghtml = [];
      if (pages > 10) {
        var currentpage = this.props.active;
        var begin = 1;
        var end = pages;
        var showpages = +pages ;
        if (currentpage < 5) { begin = 1; }
        else { begin = +currentpage - +4; }
        if (+currentpage + +4 > pages) { end = pages; }
        else { end = +currentpage + +4; }
        if (begin > 1) {
          paghtml.push(<li class='page-item'><ActionLink page="1" symbol="1" search={this.props.search} /></li>);
          paghtml.push(<li class='page-item'><ActionLink page="1" symbol=".." search={this.props.search} /></li>);
        }
        for (i = begin; i <= end; i++) {
          if (i == this.props.active) { paghtml.push(<li class='page-item active-page'><ActionLink page={i} search={this.props.search} /></li>); }
          else {
            paghtml.push(<li class='page-item'><ActionLink page={i} search={this.props.search} /></li>);
          }
        }

        if (end < showpages) {
          paghtml.push(<li class='page-item'><ActionLink page={pages} symbol=".." search={this.props.search} /></li>);
          paghtml.push(<li class='page-item'><ActionLink page={pages} symbol={pages} search={this.props.search} /></li>);
        }
      }
      else {
        for (i = 1; i <= pages; i++) {
          if (i == this.props.active) { paghtml.push(<li class='page-item active-page'><ActionLink page={i} search={this.props.search} /></li>); }
          else {
            paghtml.push(<li class='page-item'><ActionLink page={i} search={this.props.search} /></li>);
          }
        }
      }
      return (<div className="paginationblock">
        <ul className="pagination">
          {paghtml}
        </ul>
      </div>);
    }
  }
}
class Filter extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      total: [],
      activeselection: null,
      filter: [],
      tag: null,
      search: null,
      inputs: {
        category: "",
        producer_id: "",
        type: "",
        season: ""
      },
      categoryoption: [],
      producer_idoption: [],
      typeoption: [],
      seasonoption: []
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange({ target }) {
    this.setState({
      ...this.state,
      inputs: {
        ...this.state.inputs,
        [target.name]: target.value
      }
    });
  }
  componentDidMount() {
    this._isMounted = true;
    if (this.props.search !== undefined) { this.state.search = this.props.search; }
    else { this.state.search = ""; }
    var url = "http://camerabuyer.jef.it/fieldlist/category";
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            categoryoption: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
    var url = "http://camerabuyer.jef.it/fieldlist/producer_id";
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            producer_idoption: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
    var url = "http://camerabuyer.jef.it/fieldlist/type";
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            typeoption: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
    var url = "http://camerabuyer.jef.it/fieldlist/season";
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            seasonoption: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  render() {
    const { error, isLoaded, filter, search } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="col-md-3">Loading...</div>;
    } else {
      var urlsearchfilter = `/1/${search}`;
      //var urlsearchfilter = `/1/`;
      var checkparameters = [];
      if ((this.state.inputs.category !== undefined) && (this.state.inputs.category !== null) && (this.state.inputs.category != "")) {
        checkparameters = urlsearchfilter.split("_");
        var k = 0; var newstate = "";
        checkparameters.forEach(function (param) {
          if (param.indexOf("c-") != -1) {
            delete checkparameters[k];
          }
          k++;
        });
        checkparameters.forEach(function (param) {
          if (param.trim() != "") {
            newstate = newstate + param;
          }
        });
        urlsearchfilter = newstate;
        urlsearchfilter = urlsearchfilter + `_c-${this.state.inputs.category}`;
      }
      if ((this.state.inputs.type !== undefined) && (this.state.inputs.type !== null) && (this.state.inputs.type != "")) {
        checkparameters = urlsearchfilter.split("_");
        var k = 0; var newstate = "";
        checkparameters.forEach(function (param) {
          if (param.indexOf("t-") != -1) {
            delete checkparameters[k];
          }
          k++;
        });
        checkparameters.forEach(function (param) {
          if (param.trim() != "") {
            newstate = newstate + param + "_";
          }
        });
        urlsearchfilter = newstate;
        if (this.state.inputs.type.trim() != "") {
          urlsearchfilter = urlsearchfilter + `_t-${this.state.inputs.type}`;
        }
      }
      if ((this.state.inputs.producer_id !== undefined) && (this.state.inputs.producer_id !== null) && (this.state.inputs.producer_id != "")) {
        checkparameters = urlsearchfilter.split("_");
        var k = 0; var newstate = "";
        checkparameters.forEach(function (param) {
          if (param.indexOf("p-") != -1) {
            delete checkparameters[k];
          }
          k++;
        });
        checkparameters.forEach(function (param) {
          if (param.trim() != "") {
            newstate = newstate + param + "_";
          }
        });
        urlsearchfilter = newstate;
        if (this.state.inputs.producer_id.trim() != "") {
          urlsearchfilter = urlsearchfilter + `_p-${this.state.inputs.producer_id}`;
        }
      }
      if ((this.state.inputs.season !== undefined) && (this.state.inputs.season !== null) && (this.state.inputs.season != "")) {
        checkparameters = urlsearchfilter.split("_");
        var k = 0; var newstate = "";
        checkparameters.forEach(function (param) {
          if (param.indexOf("s-") != -1) {
            delete checkparameters[k];
          }
          k++;
        });
        checkparameters.forEach(function (param) {
          if (param.trim() != "") {
            newstate = newstate + param + "_";
          }
        });
        urlsearchfilter = newstate;
        if (this.state.inputs.season.trim() != "") {
          urlsearchfilter = urlsearchfilter + `_s-${this.state.inputs.season}`;
        }
      }
      var categoryselected = [];
      this.state.categoryoption.forEach(function (filter) {
        if ((search.indexOf(filter.filter) != -1) && (search !== undefined)) {
          categoryselected.push(<option selected="selected" key={filter.filter} >{filter.filter}</option>);
        }
        else { categoryselected.push(<option key={filter.filter} >{filter.filter}</option>); }
      });
      var typeselected = [];
      this.state.typeoption.forEach(function (filter) {
        if ((search.indexOf(filter.filter) != -1) && (search !== undefined)) {
          typeselected.push(<option selected="selected" key={filter.filter} >{filter.filter}</option>);
        }
        else { typeselected.push(<option key={filter.filter} >{filter.filter}</option>); }
      });
      var producer_idselected = [];
      this.state.producer_idoption.forEach(function (filter) {
        if ((search.indexOf(filter.filter) != -1) && (search !== undefined)) {
          producer_idselected.push(<option selected="selected" key={filter.filter} >{filter.filter}</option>);
        }
        else { producer_idselected.push(<option key={filter.filter} >{filter.filter}</option>); }
      });
      var seasonselected = [];
      this.state.seasonoption.forEach(function (filter) {
        if ((search.indexOf(filter.filter) != -1) && (search !== undefined)) {
          seasonselected.push(<option selected="selected" key={filter.filter} >{filter.filter}</option>);
        }
        else { seasonselected.push(<option key={filter.filter} >{filter.filter}</option>); }
      });
      return (<div className="row blockfilter">
        <div className="col-md-2">
          <div className="hidedropdown">
            <select name="category" value={this.state.inputs.category} onChange={this.handleChange} className="filter" value={this.state.value}>
              <option value="">Seleziona categoria</option>
              {categoryselected}
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="hidedropdown">
            <select name="producer_id" value={this.state.inputs.producer_id} onChange={this.handleChange} className="filter" value={this.state.value}>
              <option value="">Seleziona marchio</option>
              {producer_idselected}
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="hidedropdown">
            <select name="type" value={this.state.inputs.type} onChange={this.handleChange} className="filter" value={this.state.value}>
              <option value="">Seleziona tipologia</option>
              {typeselected}
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="hidedropdown">
            <select name="season" value={this.state.inputs.season} onChange={this.handleChange} className="filter" value={this.state.value}>
              <option value="">Seleziona stagione</option>
              {seasonselected}
            </select>
          </div>
        </div><div className="col-md-2 tothecentre">
          <a className="cercabox" href={urlsearchfilter}>cerca</a><br />
        </div><div className="col-md-2 tothecentre">
          <a className="cercabox" href="/">pulisci la ricerca</a>
        </div></div>);
    }
  }
}
const element = <App />;
ReactDOM.render(
  element,
  document.getElementById('root')
);